<template>
  <bb-application-page
      :form-props="{
        uppercaseButtons: false,
        errorText: translations.errors.errorText,
        submitText: translations.submitText,
        disabled: isLoading,
        loading: isLoading,
      }"
      @submit="onSubmit"
  >
    <bb-public-header
        #header
        :title="translations.header"
        icon="client-details"
    />
    <p v-if="errorMessage" class="f-color-red align-center f-gotham f-small m-t-10 m-b-20">
      {{ errorMessage }}
    </p>
    <bb-select
        v-model="form.taxResidency"
        v-validate="'required'"
        :options="countryOptionsWithExcludedStateless"
        :label="translations.taxResidency"
        :data-vv-as="translations.taxResidency"
        :helpActive="true"
        :helpText="translations.taxResidencyHelp"
        name="taxResidency"
        :mandatory="true"
    />
    <bb-masked-input
        v-if="TIN.isVisible && TIN.isMaskUsed"
        name="taxIdNumber"
        v-validate="TIN.validationRules"
        :data-vv-as="translations.taxIdNumber"
        :label="translations.taxIdNumber"
        v-model="form.taxIdNumber"
        :mask="TIN.mask"
        :mandatory="true"
    />
    <bb-input
        v-if="TIN.isVisible && !TIN.isMaskUsed"
        name="taxIdNumber"
        v-validate="TIN.validationRules"
        :data-vv-as="translations.taxIdNumber"
        :label="translations.taxIdNumber"
        v-model="form.taxIdNumber"
        :mandatory="true"
    />
    <bb-input
        name="totalIncome"
        v-validate="'required'"
        data-vv-validate-on="change"
        :data-vv-as="translations.totalIncome"
        :label="translations.totalIncome"
        :helpText="translations.totalIncomeHelp"
        :helpActive="true"
        currency
        add-on="€"
        v-model="form.totalIncome"
        :mandatory="true"
    />
    <bb-typeahead
        name="incomeTypes"
        data-testid="incomeTypes"
        v-model="selectedIncomeTypes"
        v-validate="'required'"
        :options="incomeTypes"
        :label="translations.incomeTypeLabel"
        :data-vv-as="translations.incomeTypeLabel"
        label-prop-name="text"
        track-by="value"
        multiple
        :mandatory="true"
    />
    <bb-input
        v-if="specifiedIncomeTypes.isVisible"
        v-model="form.specifiedIncomeTypes"
        v-validate="specifiedIncomeTypes.validationRules"
        :label="translations.specifiedIncomeTypes"
        :data-vv-as="translations.specifiedIncomeTypes"
        name="specifiedIncomeTypes"
        :mandatory="true"
    />
    <bb-typeahead
        name="purposeOfCustomerRelationship"
        data-testid="purposeOfCustomerRelationship"
        v-model="selectedPurposes"
        v-validate="'required'"
        :options="purposesOfRelationship"
        :label="translations.purposeOfRelationship"
        :data-vv-as="translations.purposeOfRelationship"
        label-prop-name="text"
        track-by="value"
        multiple
        :mandatory="true"
    />
    <bb-select
        v-model="form.plannedMonthlyTurnover"
        v-validate="'required'"
        :options="turnoverOptions"
        :label="translations.plannedMonthlyTurnover"
        :data-vv-as="translations.plannedMonthlyTurnover"
        :helpActive="true"
        :helpText="translations.plannedMonthlyTurnoverHelp"
        name="plannedMonthlyTurnover"
        :mandatory="true"
    />
    <bb-radio
        v-model="selectedPep"
        v-validate="'required'"
        data-vv-validate-on="change"
        :label="translations.pep"
        :options="PEP.options"
        class="p-t-10 p-r-30"
        :data-vv-as="translations.pep"
        :helpActive="true"
        :helpText="translations.pepHelp"
        name="isPep"
    />
    <text-with-links
        :text="translations.formConfirmationText"
        class="align-center f-gotham f-smallest p-t-30"
        :links="['http://www.bigbank.eu']"
    />
  </bb-application-page>
</template>

<script>
import {
  BbApplicationPage,
  BbInput,
  BbMaskedInput,
  BbPublicHeader,
  BbRadio,
  BbSelect,
  BbTypeahead
} from '@bigbank/interface-components'
import TextWithLinks from '@/components/TextWithLinks'
import RegistrationMixin from '@/views/common/RegistrationMixin.vue'
import { mapActions, mapState } from 'pinia'
import errorHandler from '@/errors/errorHandler'
import { STATELESS_COUNTRY_CODE } from '@/const'
import {
  FormValidation,
  getTINInputMask,
  isDeepTINValidationRequired,
  isTINRequired
} from '@bigbank/dc-common/validators/validation.config'
import { CBValidator } from '@bigbank/dc-common/validators/cb/CBValidator.class'
import {
  IncomeType,
  PlannedMonthlyTurnover,
  PurposeOfRelationShip
} from '@bigbank/dc-common/clients/http/crm-service/crm-service.enums'
import { useAppStore } from '@/store/app.store'
import { isBoolean } from 'lodash'

const cbValidator = new CBValidator()

export default {
  name: 'ee-current-account-additional-details',
  components: {
    BbRadio,
    BbApplicationPage,
    BbPublicHeader,
    BbInput,
    BbSelect,
    BbMaskedInput,
    BbTypeahead,
    TextWithLinks
  },
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      isLoading: true,
      selectedIncomeTypes: [],
      selectedPurposes: [],
      selectedPep: undefined,
      form: {
        taxResidency: 'EE',
        taxIdNumber: undefined,
        totalIncome: undefined,
        incomeTypes: [],
        specifiedIncomeTypes: undefined,
        purposeOfCustomerRelationship: [],
        plannedMonthlyTurnover: undefined,
        isPep: undefined
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'formData', 'countries', 'status']),
    translations () {
      return {
        header: this.$pgettext('customer_data_sheet', 'Additional details'),
        taxResidency: this.$pgettext('customer_data_sheet', 'Tax Residency'),
        taxResidencyHelp: this.$pgettext('customer_data_sheet', 'TAX_RESIDENCY_HELP_TEXT'),
        taxIdNumber: this.$pgettext('customer_data_sheet', 'Tax ID Number'),
        totalIncome: this.$pgettext('customer_data_sheet', 'Total income'),
        totalIncomeHelp: this.$pgettext('customer_data_sheet', 'Total earnings per month'),
        incomeTypeLabel: this.$pgettext('customer_data_sheet', 'Income types'),
        specifiedIncomeTypes: this.$pgettext('customer_data_sheet', 'Specify income'),
        purposeOfRelationship: this.$pgettext('customer_data_sheet', 'Purpose of customer relationship'),
        plannedMonthlyTurnover: this.$pgettext('customer_data_sheet', 'Planned monthly cash turnover'),
        plannedMonthlyTurnoverHelp: this.$pgettext('customer_data_sheet', 'Total planned expenditure per month'),
        pep: this.$pgettext('customer_data_sheet', 'Are you a PEP?'),
        pepHelp: this.$pgettext('customer_data_sheet', 'PEP_HELP_TEXT'),
        submitText: this.$pgettext('customer_data_sheet', 'Finish data check'),
        formConfirmationText: this.$pgettext('customer_data_sheet', 'FORM_CONFIRMATION_TEXT [terms_link_text](http://terms_link_url)'),
        dataProcessingSilentConsentText: this.$pgettext('customer_data_sheet', 'DATA_PROCESSING_SILENT_CONSENT_TEXT [terms_link_text](http://terms_link_url)'),
        errors: {
          errorText: this.$pgettext('customer_data_sheet', 'FORM_HAS_ERRORS_MESSAGE')
        },
        incomeTypes: {
          [IncomeType.Salary]: this.$pgettext('customer_data_sheet', 'Salary'),
          [IncomeType.ParentalBenefit]: this.$pgettext('customer_data_sheet', 'Parental benefit'),
          [IncomeType.Pension]: this.$pgettext('customer_data_sheet', 'Pension'),
          [IncomeType.SocialBenefit]: this.$pgettext('customer_data_sheet', 'Social benefit'),
          [IncomeType.Savings]: this.$pgettext('customer_data_sheet', 'Savings'),
          [IncomeType.Rent]: this.$pgettext('customer_data_sheet', 'Rent'),
          [IncomeType.Alimony]: this.$pgettext('customer_data_sheet', 'Alimony'),
          [IncomeType.Dividends]: this.$pgettext('customer_data_sheet', 'Dividends'),
          [IncomeType.FriendsFamily]: this.$pgettext('customer_data_sheet', 'Friends/Family'),
          [IncomeType.Other]: this.$pgettext('customer_data_sheet', 'Other')
        },
        purposesOfRelationship: {
          [PurposeOfRelationShip.EveryDayBanking]: this.$pgettext('customer_data_sheet', 'Everyday banking'),
          [PurposeOfRelationShip.Loan]: this.$pgettext('customer_data_sheet', 'Loan'),
          [PurposeOfRelationShip.Saving]: this.$pgettext('customer_data_sheet', 'Saving')
        }
      }
    },
    countryOptionsWithExcludedStateless () {
      return this.getCountriesList([STATELESS_COUNTRY_CODE])
    },
    incomeTypes () {
      const supportedIncomes = [IncomeType.Salary, IncomeType.ParentalBenefit, IncomeType.Pension, IncomeType.SocialBenefit, IncomeType.Savings, IncomeType.Rent, IncomeType.Alimony, IncomeType.Dividends, IncomeType.FriendsFamily, IncomeType.Other]
      return supportedIncomes.map(incomeType => ({
        text: this.translations.incomeTypes[incomeType] ?? incomeType,
        value: incomeType,
        id: incomeType
      }))
    },
    TIN () {
      const isDeepValidationRequired = isDeepTINValidationRequired(FormValidation.CurrentAccountOnboarding, this.form.taxResidency, this.form.taxResidency)
      const isRequired = isTINRequired(FormValidation.CurrentAccountOnboarding, this.channel, this.form.taxResidency)

      return {
        isVisible: isRequired,
        isMaskUsed: isDeepValidationRequired,
        mask: isDeepValidationRequired ? getTINInputMask(FormValidation.CurrentAccountOnboarding, this.form.taxResidency) : null,
        validationRules: {
          required: isDeepValidationRequired || isRequired,
          taxIdNumber: isDeepValidationRequired
        }
      }
    },
    specifiedIncomeTypes () {
      const isRequired = this.selectedIncomeTypes.some(income => income.value === IncomeType.Other)

      return {
        isVisible: isRequired,
        validationRules: {
          required: isRequired
        }
      }
    },
    purposesOfRelationship () {
      return Object.values(PurposeOfRelationShip)
        .filter(purpose => ![PurposeOfRelationShip.Deposits].includes(purpose))
        .map(purpose => ({
          text: this.translations.purposesOfRelationship[purpose] ?? purpose,
          value: purpose,
          id: purpose
        }))
    },
    turnoverOptions () {
      const texts = {
        [PlannedMonthlyTurnover['0_1000']]: '0-1000',
        [PlannedMonthlyTurnover['1001_2500']]: '1001-2500',
        [PlannedMonthlyTurnover['2501_5000']]: '2501-5000',
        [PlannedMonthlyTurnover['5001_15000']]: '5001-15000',
        [PlannedMonthlyTurnover.MORE_THAN_15000]: '>15001'
      }

      return Object.entries(texts)
        .map(([key, value]) => ({
          text: value,
          value: key
        }))
    },
    PEP () {
      return {
        options: [{
          value: 'NO',
          text: this.$pgettext('customer_data_sheet', 'No')
        }, {
          value: 'YES',
          text: this.$pgettext('customer_data_sheet', 'Yes')
        }]
      }
    }
  },
  watch: {
    'form.taxResidency' () {
      const isTaxIdNumberFilled = (this.form.taxIdNumber ?? '').trim().length > 0

      if (this.TIN.validationRules.required && isTaxIdNumberFilled) {
        setTimeout(() => this.$validator.validate('taxIdNumber'), 1000)
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'updateFormData', 'getCountries']),
    async onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true

      this.form.incomeTypes = this.selectedIncomeTypes.map(income => income.value)
      this.form.purposeOfCustomerRelationship = this.selectedPurposes.map(purpose => purpose.value)
      this.form.isPep = this.selectedPep === 'YES'
      if (!this.TIN.isVisible) {
        this.form.taxIdNumber = undefined
      }
      if (!this.specifiedIncomeTypes.isVisible) {
        this.form.specifiedIncomeTypes = undefined
      }
      try {
        await this.updateFormData({ additionalDetails: this.form })
        await this.registerFormData(this.formData)
        await this.getStatus()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    getCountriesList (excludeByCode) {
      let countries = this.countries.map(country => ({
        value: country.countryCode,
        text: country.translation
      }))
        .sort((a, b) => a.text.localeCompare(b.text, this.locale))

      if (Array.isArray(excludeByCode)) {
        countries = countries.filter((country) => !excludeByCode.includes(country.value))
      }

      return countries
    },
    prefillInitialValues () {
      if (this.status.customer?.taxResidency) {
        this.form.taxResidency = this.status.customer?.taxResidency
        // taxIdNumber showing depends on taxResidency. For whatever reason, we must wait for field to be actually prefilled
        this.$nextTick(() => {
          this.form.taxIdNumber = this.status.customer?.taxIdNumber
        })
      }
      this.form.totalIncome = this.status.customer?.totalIncome

      if (this.status.customer?.incomeTypes?.length > 0) {
        this.selectedIncomeTypes = this.status.customer?.incomeTypes?.map(incomeType => ({
          text: this.translations.incomeTypes[incomeType] ?? incomeType,
          value: incomeType
        }))
      }
      if (this.status.customer?.purposeOfCustomerRelationship?.length > 0) {
        this.selectedPurposes = this.status.customer?.purposeOfCustomerRelationship?.map(type => ({
          text: this.translations.purposesOfRelationship[type] ?? type,
          value: type
        }))
      }
      if (this.status.customer?.specifiedIncomeTypes) {
        this.form.specifiedIncomeTypes = this.status.customer?.specifiedIncomeTypes
      }
      if (this.status.customer?.plannedMonthlyTurnover) {
        this.form.plannedMonthlyTurnover = this.status.customer?.plannedMonthlyTurnover
      }
      if (isBoolean(this.status.customer?.isPep)) {
        this.selectedPep = this.status.customer?.isPep ? 'YES' : 'NO'
        this.$validator.validate('isPep')
      }
    }
  },
  async created () {
    window.scrollTo(0, 0)
    this.$validator.extend('taxIdNumber', {
      validate: value => {
        const valueWithoutMask = value.replace(/[_\-/]/g, '')
        if (valueWithoutMask.length !== 0 && isDeepTINValidationRequired(FormValidation.CurrentAccountOnboarding, this.form.taxResidency, this.form.taxResidency)) {
          return cbValidator.isValidTaxIdNumber(value, {
            forceChannel: this.form.taxResidency
          })
        }
        if (isTINRequired(FormValidation.CurrentAccountOnboarding, this.channel, this.form.taxResidency)) {
          return (value ?? '').trim().length > 0
        }

        return true
      }
    })

    try {
      await this.getCountries()
      this.prefillInitialValues()
      this.isLoading = false
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>
